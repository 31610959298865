import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import util modules
import { isFieldForCategory, isFieldForListingType } from '../../../../util/fieldHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';
import { composeValidators, maxLength, required } from '../../../../util/validators';

// Import shared components
import {
  Button,
  CustomExtendedDataField,
  FieldCheckbox,
  FieldSelect,
  FieldTextInput,
  Form,
  H4,
  Heading
} from '../../../../components';
// Import modules from this directory
import moment from 'moment';
import { getRecurringEvents } from '../../../../util/recurringEventHelpers';
import FieldTimeZoneSelect from '../EditListingAvailabilityPanel/FieldTimeZoneSelect';
import css from './EditListingDetailsForm.module.css';

const TITLE_MAX_LENGTH = 60;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const {
    name,
    listingTypes,
    hasExistingListingType,
    onListingTypeChange,
    formApi,
    formId,
    intl,
  } = props;
  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingType === value);
    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };
  const getListingTypeLabel = listingType => {
    const listingTypeConfig = listingTypes.find(config => config.listingType === listingType);
    return listingTypeConfig ? listingTypeConfig.label : listingType;
  };

  return hasMultipleListingTypes && !hasExistingListingType ? (
    <>
      <FieldSelect
        id={formId ? `${formId}.${name}` : name}
        name={name}
        className={css.listingTypeSelect}
        label={intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
        validate={required(
          intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeRequired' })
        )}
        onChange={handleOnChange}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypePlaceholder' })}
        </option>
        {listingTypes.map(config => {
          const type = config.listingType;
          return (
            <option key={type} value={type}>
              {config.label}
            </option>
          );
        })}
      </FieldSelect>
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  ) : hasMultipleListingTypes && hasExistingListingType ? (
    <div className={css.listingTypeSelect}>
      <Heading as="h5" rootClassName={css.selectedLabel}>
        {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
      </Heading>
      <p className={css.selectedValue}>{getListingTypeLabel(formApi.getFieldState(name)?.value)}</p>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </div>
  ) : (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

// Finds the correct subcategory within the given categories array based on the provided categoryIdToFind.
const findCategoryConfig = (categories, categoryIdToFind) => {
  return categories?.find(category => category.id === categoryIdToFind);
};

/**
 * Recursively render subcategory field inputs if there are subcategories available.
 * This function calls itself with updated props to render nested category fields.
 * The select field is used for choosing a category or subcategory.
 */
const CategoryField = props => {
  const { currentCategoryOptions, level, values, prefix, handleCategoryChange, intl } = props;

  const currentCategoryKey = `${prefix}${level}`;

  const categoryConfig = findCategoryConfig(currentCategoryOptions, values[`${prefix}${level}`]);

  return (
    <>
      {currentCategoryOptions ? (
        <FieldSelect
          key={currentCategoryKey}
          id={currentCategoryKey}
          name={currentCategoryKey}
          className={css.listingTypeSelect}
          onChange={event => handleCategoryChange(event, level, currentCategoryOptions)}
          label={intl.formatMessage(
            { id: 'EditListingDetailsForm.categoryLabel' },
            { categoryLevel: currentCategoryKey }
          )}
          validate={required(
            intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryRequired' },
              { categoryLevel: currentCategoryKey }
            )
          )}
        >
          <option disabled value="">
            {intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryPlaceholder' },
              { categoryLevel: currentCategoryKey }
            )}
          </option>

          {currentCategoryOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FieldSelect>
      ) : null}

      {categoryConfig?.subcategories?.length > 0 ? (
        <CategoryField
          currentCategoryOptions={categoryConfig.subcategories}
          level={level + 1}
          values={values}
          prefix={prefix}
          handleCategoryChange={handleCategoryChange}
          intl={intl}
        />
      ) : null}
    </>
  );
};

const FieldSelectCategory = props => {
  useEffect(() => {
    checkIfInitialValuesExist();
  }, []);

  const { prefix, listingCategories, formApi, intl, setAllCategoriesChosen, values } = props;

  // Counts the number of selected categories in the form values based on the given prefix.
  const countSelectedCategories = () => {
    return Object.keys(values).filter(key => key.startsWith(prefix)).length;
  };

  // Checks if initial values exist for categories and sets the state accordingly.
  // If initial values exist, it sets `allCategoriesChosen` state to true; otherwise, it sets it to false
  const checkIfInitialValuesExist = () => {
    const count = countSelectedCategories(values, prefix);
    setAllCategoriesChosen(count > 0);
  };

  // If a parent category changes, clear all child category values
  const handleCategoryChange = (category, level, currentCategoryOptions) => {
    const selectedCatLenght = countSelectedCategories();
    if (level < selectedCatLenght) {
      for (let i = selectedCatLenght; i > level; i--) {
        formApi.change(`${prefix}${i}`, null);
      }
    }
    const categoryConfig = findCategoryConfig(currentCategoryOptions, category).subcategories;
    setAllCategoriesChosen(!categoryConfig || categoryConfig.length === 0);
  };

  return (
    <CategoryField
      currentCategoryOptions={listingCategories}
      level={1}
      values={values}
      prefix={prefix}
      handleCategoryChange={handleCategoryChange}
      intl={intl}
    />
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
const AddListingFields = props => {
  const { listingType, listingFieldsConfig, selectedCategories, formId, intl, values } = props;
  const targetCategoryIds = Object.values(selectedCategories);

  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, schemaType, scope } = fieldConfig || {};
    const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isProviderScope = ['public', 'private'].includes(scope);
    const isTargetListingType = isFieldForListingType(listingType, fieldConfig);
    const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

    const showOtherForSport = key === 'sport' && values.pub_sport === 'other'

    return isKnownSchemaType && isProviderScope && isTargetListingType && isTargetCategory
      ? [
        ...pickedFields,
        <CustomExtendedDataField
          key={namespacedKey}
          name={namespacedKey}
          fieldConfig={fieldConfig}
          defaultRequiredMessage={intl.formatMessage({
            id: 'EditListingDetailsForm.defaultRequiredMessage',
          })}
          formId={formId}
        />,
        showOtherForSport ?
          <FieldTextInput
            id={`${formId}otherSport`}
            name="otherSport"
            className={css.date}
            type="text"
            label={intl.formatMessage({ id: 'EditListingDetailsForm.otherSport' })}
            placeholder={intl.formatMessage({
              id: 'EditListingDetailsForm.otherSportPlaceholder',
            })}
            validate={required(
              intl.formatMessage({
                id: 'EditListingDetailsForm.otherSportRequired',
              }))}
          />
          : null
      ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        hideRecurringEvent,
      } = formRenderProps;

      const { listingType, transactionProcessAlias, unitType } = values;
      const [allCategoriesChosen, setAllCategoriesChosen] = useState(false);

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const showTitle = hasCategories ? allCategoriesChosen : listingType;
      const showDescription = hasCategories ? allCategoriesChosen : listingType;
      const showListingFields = hasCategories ? allCategoriesChosen : listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData;

      const recurringSchedule = (values.startDate && values.isRecurringEvent && values.recurringDaysNo && values.recurringUntilDate)
        ? getRecurringEvents(values.startDate, values.endDate, values.recurringDaysNo, values.recurringUntilDate)
        : null;

      const startDateInTimezone = values.startDate ? values.timezone ? moment(values.startDate).tz(values.timezone) : moment(values.startDate) : null;
      const endDateInTimezone = values.endDate ? values.timezone ? moment(values.endDate).tz(values.timezone) : moment(values.endDate) : null;
      const duration = startDateInTimezone && endDateInTimezone ? endDateInTimezone.diff(startDateInTimezone, 'minutes') : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            formId={formId}
            intl={intl}
          />

          {showCategories ? (
            <FieldSelectCategory
              values={values}
              prefix={categoryPrefix}
              listingCategories={selectableCategories}
              formApi={formApi}
              intl={intl}
              allCategoriesChosen={allCategoriesChosen}
              setAllCategoriesChosen={setAllCategoriesChosen}
            />
          ) : null}

          {showTitle ? (
            <FieldTextInput
              id={`${formId}title`}
              name="title"
              className={css.title}
              type="text"
              label={intl.formatMessage({ id: 'EditListingDetailsForm.title' })}
              placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.titlePlaceholder' })}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus={autoFocus}
            />
          ) : null}

          {showDescription ? (
            <FieldTextInput
              id={`${formId}description`}
              name="description"
              className={css.description}
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingDetailsForm.description' })}
              placeholder={intl.formatMessage({
                id: 'EditListingDetailsForm.descriptionPlaceholder',
              })}
              validate={required(
                intl.formatMessage({
                  id: 'EditListingDetailsForm.descriptionRequired',
                })
              )}
            />
          ) : null}

          <FieldTimeZoneSelect
            id="timezone"
            name="timezone"
            className={css.description}
            label={intl.formatMessage({ id: 'EditListingDetailsForm.timezone' })}
            placeholder={intl.formatMessage({
              id: 'EditListingDetailsForm.timezonePlaceholder',
            })}
            validate={required(
              intl.formatMessage({
                id: 'EditListingDetailsForm.timezoneRequired',
              }))}
          />


          <div className='row gap20'>

            <FieldTextInput
              id={`${formId}startDate`}
              rootClassName={css.date}
              name="startDate"
              type="datetime-local"
              min={moment().add(1, 'minutes').format("YYYY-MM-DDTHH:mm")}
              label={intl.formatMessage({ id: 'EditListingDetailsForm.startDate' })}
            />

            <FieldTextInput
              id={`${formId}endDate`}
              rootClassName={css.date}
              name="endDate"
              type="datetime-local"
              min={values.startDate ? moment(values.startDate).add(1, 'minutes').format("YYYY-MM-DDTHH:mm") : moment().add(1, 'minutes').format("YYYY-MM-DDTHH:mm")}
              label={intl.formatMessage({ id: 'EditListingDetailsForm.endDate' })}
            />

            <div className='col cenV'>
              <label>
                <FormattedMessage id="EditListingDetailsForm.duration" />
              </label>
              <p className={css.duration}>
                {duration && duration > 0 ? duration : "-"}
              </p>
              <FormattedMessage id="EditListingDetailsForm.minutes" />
            </div>

          </div>

          {showListingFields ? (
            <AddListingFields
              listingType={listingType}
              listingFieldsConfig={listingFieldsConfig}
              selectedCategories={pickSelectedCategories(values)}
              formId={formId}
              intl={intl}
              values={values}
            />
          ) : null}

          <div className='line' />

          {!hideRecurringEvent &&
            <>          <H4>
              <FormattedMessage id="EditListingDetailsForm.recurringEvent" />
            </H4>

              <p>
                <FormattedMessage id="EditListingDetailsForm.recurringEventInfo" />
              </p>

              <FieldCheckbox
                id={`${formId}isRecurringEvent`}
                name="isRecurringEvent"
                className={css.title}
                label={intl.formatMessage({ id: 'EditListingDetailsForm.recurringEvent' })}
              />


              <div className='row  gap40'>

                <div className='row'>
                  <FieldTextInput
                    id={`${formId}recurringDaysNo`}
                    className={css.title}
                    name="recurringDaysNo"
                    type="number"
                    min={1}
                    label={intl.formatMessage({ id: 'EditListingDetailsForm.recurringDaysNo' })}
                    validate={values.isRecurringEvent === true ? validators.numberAtLeast(intl.formatMessage({ id: 'EditListingDetailsForm.recurringDaysNoRequired' }), 0) : null}
                  />

                  <span className={css.suffix}>
                    <FormattedMessage id="EditListingDetailsForm.days" />
                  </span>
                </div>

                <FieldTextInput
                  id={`${formId}recurringUntilDate`}
                  className={css.title}
                  name="recurringUntilDate"
                  type="date"
                  label={intl.formatMessage({ id: 'EditListingDetailsForm.recurringUntilDate' })}
                />
              </div>

              {recurringSchedule &&
                <div className={css.eventsContainer}>
                  <p className={css.subtitle}>
                    <FormattedMessage id="EditListingDetailsForm.recurringEventsSchedule" />
                  </p>

                  <span>{recurringSchedule.map((e, i) => (
                    <p key={i}>{e.formattedStartDate} - {e.formattedEndDate}</p>
                  ))}</span>

                </div>
              }
            </>
          }

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
