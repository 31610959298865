import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { PrimaryButton, Modal } from '../../../components';
import css from './CancelListingModal.module.css';

const CancelListingModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        currentUser,
        inProgress,
        transactions,
        fetchTransactions
    } = props;

    useEffect(() => {
        if (!transactions) {
            fetchTransactions();
        }

    }, [])

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'CancelListingModal.close' });

    const submitInProgress = inProgress;
    const submitDisabled = submitInProgress;

    const getTotalSeatsPurchases = () => {
        let totalSeatsPurchased = 0;
        transactions?.map(t => {
            if (t.attributes.lastTransition === 'transition/confirm-payment') {
                totalSeatsPurchased += parseInt(t.attributes.lineItems.find(l => l.code === 'line-item/item')?.quantity?.value, 10);
            }
        });

        return totalSeatsPurchased;
    }

    const totalSeatsPurchased = getTotalSeatsPurchases();

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="CancelListingModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id="CancelListingModal.message" values={{ value: totalSeatsPurchased }} />
            </p>



            <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                onClick={onSubmitChanges}
            >
                <FormattedMessage id="CancelListingModal.confirm" />
            </PrimaryButton>


        </Modal>
    );
};

const { bool, string } = PropTypes;

CancelListingModal.defaultProps = {
    className: null,
    rootClassName: null,
};

CancelListingModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(CancelListingModal);
