import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton } from '../../../components';
import css from './AddCoHostModal.module.css';
import arrayMutators from 'final-form-arrays';

const AddCoHostModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        participants,
        currentUser,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'AddCoHostModal.close' });

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="AddCoHostModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id="AddCoHostModal.message" />
            </p>

            <FinalForm
                {...props}
                onSubmit={(values) => {
                    onSubmitChanges(values)
                }}
                mutators={{ ...arrayMutators }}
                render={fieldRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        intl,
                        formId,
                        invalid,
                        handleSubmit,
                        hasError,
                        inProgress,
                        form: {
                            mutators: { push }
                        },
                        values,
                    } = fieldRenderProps;

                    const errorMessage =
                        <p className={css.error}>
                            <FormattedMessage id="AddCoHostModal.failed" />
                        </p>
                    const errorArea = hasError ? errorMessage : <p className={css.errorPlaceholder} />;

                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || disabled || submitInProgress;

                    return (
                        <Form onSubmit={handleSubmit}>


                            <div className='row gap20 center width-full'>
                                <FieldTextInput
                                    id={`coHostEmail`}
                                    name={`coHostEmail`}
                                    type="text"
                                    className={css.input}
                                    inputRootClass={css.input}
                                    label={intl.formatMessage({ id: 'AddCoHostModal.email' })}
                                    placeholder={intl.formatMessage({ id: 'AddCoHostModal.placeholder' })}
                                />
                            </div>

                            {errorArea}
                            <div className={css.modalButtons}>
                                <SecondaryButton
                                    className={css.modalButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCloseModal();
                                    }}
                                >
                                    <FormattedMessage id="AddCoHostModal.back" />
                                </SecondaryButton>

                                <PrimaryButton
                                    className={css.submitButton}
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                >
                                    <FormattedMessage id="AddCoHostModal.confirm" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />


        </Modal>
    );
};

const { bool, string } = PropTypes;

AddCoHostModal.defaultProps = {
    className: null,
    rootClassName: null,
};

AddCoHostModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(AddCoHostModal);
