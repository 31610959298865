import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, PrimaryButton } from '../../components';

import css from './ListingPage.module.css';
import SoccerIcon from './images/soccer_icon.svg';
import NoImageIcon from '../../components/ResponsiveImage/NoImageIcon';
import ManageParticipantsModal from './ManageParticipantsModal/ManageParticipantsModal';
import { post } from '../../util/api';
import JoinWaitingListModal from './JoinWaitingList/JoinWaitingListModal';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionWaitingListMaybe = props => {
  const { isJoinWaitingListModalOpen, setJoinWaitingListModalOpen, publicData, isOwnListing, intl, onManageDisableScrolling, currentUser, listingId, onLoadData, config } = props;
  if (!publicData) {
    return null;
  }

  const [inProgress, setInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);

  const waitingList = publicData.waitingList || [];
  const hasWaitingList = waitingList && waitingList.length > 0;

  const onSubmitChanges = (newWaitingList) => {
    const validNewWaitingList = newWaitingList.filter(
      (item) => item.displayName && item.displayName.trim() !== ""
    );

    setInProgress(true);
    if (isOwnListing) {
      sdk.ownListings.show({ id: listingId }).then(res => {
        const realTimeWaitingList = Array.isArray(res.data.data.attributes.publicData.waitingList)
          ? res.data.data.attributes.publicData.waitingList : [];

        const mergedList = [...realTimeWaitingList, ...validNewWaitingList];
        const updatedWaitingList = Array.from(new Map(mergedList.map(item => [item.id, item])).values());

        sdk.ownListings.update({
          id: listingId,
          publicData: {
            waitingList: updatedWaitingList
          }
        }).then(r => {
          setInProgress(false);
          setJoinWaitingListModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
      })
    } else {
      post('/api/add-waiting-list',
        {
          listingId: listingId,
          currentWaitingListArray: validNewWaitingList
        })
        .then(r => {
          setInProgress(false);
          setJoinWaitingListModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
    }
  }

  return (
    <section className={css.sectionParticipants}>
      <div className='rowResponsive between fullWidth cenV'>
        <Heading as="h2" rootClassName={css.participantsTitle}>
          <FormattedMessage id="ListingPage.waitingList" />
        </Heading>

        {currentUser &&
          <PrimaryButton className={css.button} onClick={() => setJoinWaitingListModalOpen(true)}>
            <FormattedMessage id="ListingPage.joinWaitingList" />
          </PrimaryButton>
        }
      </div>

      {hasWaitingList ?
        <div className={css.participantsContainer}>
          {waitingList.map((user, index) => (
            <div key={`${index}-${user.displayName}`} className={css.participant}>

              {user.profilePic ?
                <img src={user.profilePic} className={css.participantImage} alt="profile pic" />
                : <div className={css.participantNoImageContainer}>
                  <img src={SoccerIcon} className={css.participantNoImage} />
                </div>
              }
              <div className='row cenV'>
                <img src={SoccerIcon} className={css.participantIcon} />

                <div className='col'>
                  <span className={css.participantName}>{" " + (index + 1) + ".  " + user.displayName}</span>
                </div>

              </div>
            </div>
          ))}
        </div>
        :
        <Heading as="h2" rootClassName={css.noParticipants}>
          <FormattedMessage id="ListingPage.noWaitingList" />
        </Heading>
      }

      {isJoinWaitingListModalOpen &&
        <JoinWaitingListModal
          id="JoinWaitingListModal"
          isOpen={isJoinWaitingListModalOpen}
          onCloseModal={() => setJoinWaitingListModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          currentUser={currentUser}
          inProgress={inProgress}
          hasError={hasError}
          onSubmitChanges={(values) => {
            onSubmitChanges(values);
          }}
        />
      }
    </section>
  )
};

export default SectionWaitingListMaybe;
