import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconSpinner } from '..';

import css from './FieldAzureImageUpload.module.css';

const RemoveImageButton = props => {
    const { className, rootClassName, onClick } = props;
    const classes = classNames(rootClassName || css.removeImage, className);
    return (
        <button className={classes} onClick={onClick}>

            <svg
                width="10px"
                height="10px"
                viewBox="0 0 10 10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g strokeWidth="1" fillRule="evenodd">
                    <g transform="translate(-821.000000, -311.000000)">
                        <g transform="translate(809.000000, 299.000000)">
                            <path
                                d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                                transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    );
};

const FieldAzureImageUpload = props => {
    const {
        name,
        id,
        label,
        onUploadComplete,
        onImageRemove,
        ...rest
    } = props;

    const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
    const [imageUrl, setImageUrl] = useState();
    const [isUploading, setIsUploading] = useState(false);

    return (
        <Field id={id} name={name} {...rest}>
            {fieldprops => {
                const { input: { onChange, value }, disabled: fieldDisabled } = fieldprops;

                useEffect(() => {
                    if (value) {
                        (async () => {
                            try {
                                const response = await axios.get(
                                    `${isDev ? 'http://localhost:3500' : ''}/api/azure-stream?fileName=${value}`
                                );
                                setImageUrl(response.data);
                            } catch (error) {
                                console.log(error);
                            }
                        })();
                    }
                }, [value]);

                const onFileChange = async (event) => {
                    setIsUploading(true);
                    const formData = new FormData();
                    formData.append('image', event.target.files[0], event.target.files[0].name);

                    try {
                        const resp = await axios.post(`${isDev ? 'http://localhost:3500' : ''}/api/azure-upload`, formData);
                        const file = resp?.data?.file;
                        onChange(file?.name);
                        setImageUrl(file?.url);
                        if (onUploadComplete) {
                            onUploadComplete();
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsUploading(false);
                    }
                };

                const handleDeleteFile = async (e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    setIsUploading(true);

                    try {
                        const resp = await axios.get(
                            `${isDev ? 'http://localhost:3500' : ''}/api/azure-delete?fileName=` + value,
                            { responseType: 'blob', }
                        );
                        setImageUrl();
                        if (onImageRemove) {
                            onImageRemove();
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsUploading(false);
                    }
                };

                if (value && imageUrl) {
                    return (
                        <div className={css.fileContainer} title="download">
                            <img className={css.image} src={imageUrl} alt="image file"
                                onClick={() => window.open(imageUrl)} />

                            <RemoveImageButton onClick={handleDeleteFile} />
                        </div>
                    );
                }

                if (isUploading) {
                    return (
                        <div className={css.wrapper}>
                            <div className={css.addFileContainer}>
                                <IconSpinner />
                            </div>
                        </div>
                    )
                }

                const inputProps = {
                    id,
                    name,
                    onChange: onFileChange,
                    type: "file",
                    disabled: fieldDisabled,
                };

                return (
                    <div className={css.wrapper}>
                        <input
                            className={css.hiddenFileInput}
                            {...inputProps}
                        />
                        <label htmlFor={name} className={css.addFileContainer}>
                            {label}
                        </label>
                    </div>
                );
            }}
        </Field>
    );
};

export default memo(FieldAzureImageUpload);