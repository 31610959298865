import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes, SCHEMA_TYPE_ENUM } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton } from '../../../components';
import css from './EditListingDetailsModal.module.css';
import EditListingDetailsForm from '../../EditListingPage/EditListingWizard/EditListingDetailsPanel/EditListingDetailsForm';
import { isFieldForCategory, isFieldForListingType, pickCategoryFields } from '../../../util/fieldHelpers';
import { getDefaultTimeZoneOnBrowser } from '../../../util/dates';
import moment from 'moment';

const hasSetListingType = publicData => {
    const { listingType, transactionProcessAlias, unitType } = publicData;
    const existingListingTypeInfo = { listingType, transactionProcessAlias, unitType };

    return {
        hasExistingListingType: !!listingType && !!transactionProcessAlias && !!unitType,
        existingListingTypeInfo,
    };
};

const getTransactionInfo = (listingTypes, existingListingTypeInfo = {}, inlcudeLabel = false) => {
    const { listingType, transactionProcessAlias, unitType } = existingListingTypeInfo;

    if (listingType && transactionProcessAlias && unitType) {
        return { listingType, transactionProcessAlias, unitType };
    } else if (listingTypes.length === 1) {
        const { listingType: type, label, transactionType } = listingTypes[0];
        const { alias, unitType: configUnitType } = transactionType;
        const labelMaybe = inlcudeLabel ? { label: label || type } : {};
        return {
            listingType: type,
            transactionProcessAlias: alias,
            unitType: configUnitType,
            ...labelMaybe,
        };
    }
    return {};
};

const defaultTimeZone = () =>
    typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';


const initialValuesForListingFields = (
    data,
    targetScope,
    targetListingType,
    targetCategories,
    listingFieldConfigs
) => {
    const targetCategoryIds = Object.values(targetCategories);

    return listingFieldConfigs.reduce((fields, fieldConfig) => {
        const { key, scope = 'public', schemaType, enumOptions } = fieldConfig || {};
        const namespacePrefix = scope === 'public' ? `pub_` : `priv_`;
        const namespacedKey = `${namespacePrefix}${key}`;

        const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
        const isEnumSchemaType = schemaType === SCHEMA_TYPE_ENUM;
        const shouldHaveValidEnumOptions =
            !isEnumSchemaType ||
            (isEnumSchemaType && !!enumOptions?.find(conf => conf.option === data?.[key]));
        const isTargetScope = scope === targetScope;
        const isTargetListingType = isFieldForListingType(targetListingType, fieldConfig);
        const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

        if (
            isKnownSchemaType &&
            isTargetScope &&
            isTargetListingType &&
            isTargetCategory &&
            shouldHaveValidEnumOptions
        ) {
            const fieldValue = data?.[key] || null;
            return { ...fields, [namespacedKey]: fieldValue };
        }
        return fields;
    }, {});
};

const pickListingFieldsData = (
    data,
    targetScope,
    targetListingType,
    targetCategories,
    listingFieldConfigs
) => {
    const targetCategoryIds = Object.values(targetCategories);

    return listingFieldConfigs.reduce((fields, fieldConfig) => {
        const { key, scope = 'public', schemaType } = fieldConfig || {};
        const namespacePrefix = scope === 'public' ? `pub_` : `priv_`;
        const namespacedKey = `${namespacePrefix}${key}`;

        const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
        const isTargetScope = scope === targetScope;
        const isTargetListingType = isFieldForListingType(targetListingType, fieldConfig);
        const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

        if (isKnownSchemaType && isTargetScope && isTargetListingType && isTargetCategory) {
            const fieldValue = data[namespacedKey] || null;
            return { ...fields, [key]: fieldValue };
        } else if (isKnownSchemaType && isTargetScope) {
            // Note: this clears extra custom fields
            // These might exists if provider swaps between listing types before saving the draft listing.
            return { ...fields, [key]: null };
        }
        return fields;
    }, {});
};

const EditListingDetailsModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        listing,
        config,
        publicData,
        inProgress,
        hasError,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'EditListingDetailsModal.close' });

    const { hasExistingListingType, existingListingTypeInfo } = hasSetListingType(listing.attributes.publicData);
    const listingTypes = config.listing.listingTypes;
    const listingFields = config.listing.listingFields;
    const listingCategories = config.categoryConfiguration.categories;
    const categoryKey = config.categoryConfiguration.key;

    const getInitialValues = (
        listing,
        existingListingTypeInfo,
        listingTypes,
        listingFields,
        listingCategories,
        categoryKey
    ) => {
        const { description, title, publicData, privateData } = listing?.attributes || {};
        const { listingType,
            startDate,
            endDate,
            timezone
        } = publicData;

        const tz = timezone || defaultTimeZone();
        const nestedCategories = pickCategoryFields(publicData, categoryKey, 1, listingCategories);
        // Initial values for the form
        return {
            title,
            description,
            ...nestedCategories,
            // Transaction type info: listingType, transactionProcessAlias, unitType
            ...getTransactionInfo(listingTypes, existingListingTypeInfo),
            ...initialValuesForListingFields(
                publicData,
                'public',
                listingType,
                nestedCategories,
                listingFields
            ),
            ...initialValuesForListingFields(
                privateData,
                'private',
                listingType,
                nestedCategories,
                listingFields
            ),
            timezone: tz,
            startDate: moment(startDate).tz(tz).format('YYYY-MM-DDTHH:mm'),
            endDate: moment(endDate).tz(tz).format('YYYY-MM-DDTHH:mm'),
        };
    };

    const initialValues = getInitialValues(
        listing,
        existingListingTypeInfo,
        listingTypes,
        listingFields,
        listingCategories,
        categoryKey
    );

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="EditListingDetailsModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id="EditListingDetailsModal.message" />
            </p>

            <EditListingDetailsForm
                className={css.form}
                initialValues={initialValues}
                saveActionMsg={"Save"}
                onSubmit={values => {
                    const {
                        title,
                        description,
                        listingType,
                        transactionProcessAlias,
                        unitType,
                        startDate,
                        endDate,
                        timezone,
                        // isRecurringEvent,
                        // recurringDaysNo,
                        // recurringUntilDate,
                        ...rest
                    } = values;

                    const nestedCategories = pickCategoryFields(rest, categoryKey, 1, listingCategories);
                    // Remove old categories by explicitly saving null for them.
                    const cleanedNestedCategories = {
                        ...[1, 2, 3].reduce((a, i) => ({ ...a, [`${categoryKey}${i}`]: null }), {}),
                        ...nestedCategories,
                    };
                    const publicListingFields = pickListingFieldsData(
                        rest,
                        'public',
                        listingType,
                        nestedCategories,
                        listingFields
                    );
                    const privateListingFields = pickListingFieldsData(
                        rest,
                        'private',
                        listingType,
                        nestedCategories,
                        listingFields
                    );

                    // New values for listing attributes
                    const updateValues = {
                        title: title.trim(),
                        description,
                        publicData: {
                            listingType,
                            startDate: moment(startDate).toISOString(),
                            endDate: moment(endDate).toISOString(),
                            timezone,
                            // isRecurringEvent,
                            // recurringDaysNo,
                            // recurringUntilDate,
                            transactionProcessAlias,
                            unitType,
                            ...cleanedNestedCategories,
                            ...publicListingFields,
                        },
                        privateData: privateListingFields,
                    };

                    console.log(updateValues);
                    onSubmitChanges(updateValues);
                }}
                selectableListingTypes={listingTypes.map(conf => getTransactionInfo([conf], {}, true))}
                hasExistingListingType={hasExistingListingType}
                selectableCategories={listingCategories}
                pickSelectedCategories={values =>
                    pickCategoryFields(values, categoryKey, 1, listingCategories)
                }
                categoryPrefix={categoryKey}
                onListingTypeChange={() => { }}
                listingFieldsConfig={listingFields}
                marketplaceCurrency={config.currency}
                hideRecurringEvent={true}
                updateInProgress={inProgress}
            // disabled={disabled}
            // ready={ready}
            // updated={panelUpdated}
            // updateInProgress={updateInProgress}
            // fetchErrors={errors}
            // autoFocus
            />


        </Modal>
    );
};

const { bool, string } = PropTypes;

EditListingDetailsModal.defaultProps = {
    className: null,
    rootClassName: null,
};

EditListingDetailsModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(EditListingDetailsModal);
