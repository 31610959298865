import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, PrimaryButton } from '../../components';

import css from './ListingPage.module.css';
import CancelListingModal from './CancelListingModal/CancelListingModal';
import AddCoHostModal from './AddCoHostModal/AddCoHostModal';
import EditListingDetailsModal from './EditListingDetailsModal/EditListingDetailsModal';
import { post } from '../../util/api';
import ManageGalleryModal from './ManageGalleryModal/ManageGalleryModal';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionAdministrationMaybe = props => {
  const { listing, publicData, isOwnListing, intl, onManageDisableScrolling, currentUser, listingId, onLoadData, config } = props;

  const isCoHost = currentUser ? currentUser?.attributes.email === publicData.coHostEmail : false;

  if (!publicData) {
    return null;
  }

  if (!isOwnListing && !isCoHost) {
    return null;
  }

  if (!isOwnListing && !isCoHost) {
    return null;
  }


  const [isCancelListingModalOpen, setCancelListingModalOpen] = useState(false);
  const [isAddCoHostModalOpen, setAddCoHostModalOpen] = useState(false);
  const [isEditListingModalOpen, setEditListingModalOpen] = useState(false);
  const [isManageGalleryModalOpen, setManageGalleryModalOpen] = useState(false);


  const [inProgress, setInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [transactions, setTransactions] = useState();


  const onSubmitChanges = async () => {
    setInProgress(true);

    const transactionsToCancel = transactions.filter(t => t.attributes.lastTransition === 'transition/confirm-payment' || t.attributes.lastTransition === 'transition/confirm-purchase');

    const transactionsToCancelPromises = transactionsToCancel?.map(t =>
      sdk.transactions.transition({ id: t.id.uuid, transition: "transition/provider-cancel", params: {} }, {})
    );

    try {
      await Promise.all(transactionsToCancelPromises);

      // Update the listing to clear participants
      await sdk.ownListings.update({
        id: listingId,
        publicData: { participants: [], waitingList: [] },
      });

      // Close the listing
      await sdk.ownListings.close({ id: listingId }, {});

      // Success: Redirect and close modal
      setInProgress(false);
      setCancelListingModalOpen(false);
      window.location.href = '/listings';

    } catch (error) {
      console.error("Error while canceling event:", error);
      setHasError(true);
      setInProgress(false);
    }
  }

  const onAddCoHostChanges = (values) => {
    setInProgress(true);
    if (isOwnListing) {

      sdk.ownListings.update({
        id: listingId,
        publicData: {
          coHostEmail: values.coHostEmail
        }
      }).then(r => {
        setInProgress(false);
        setAddCoHostModalOpen(false);
        onLoadData({ id: listingId }, null, config)
      }).catch(e => {
        setHasError(true);
      })
    }
  }

  const onCoHostListingEdit = (values) => {
    setInProgress(true);
    post('/api/edit-listing',
      {
        listingId: listingId,
        updatedValuesArray: values
      })
      .then(r => {
        setInProgress(false);
        setEditListingModalOpen(false);
        onLoadData({ id: listingId }, null, config)
      }).catch(e => {
        setHasError(true);
      })
  }

  const onUpdateGallery = (values) => {
    const gallery = values.gallery.filter(i => i !== '');

    setInProgress(true);
    if (isOwnListing) {
      sdk.ownListings.show({ id: listingId }).then(res => {
        const realTimeGallery = Array.isArray(res.data.data.attributes.publicData.gallery)
          ? res.data.data.attributes.publicData.gallery
          : [];
        // Only include images that are in gallery (not removed)
        const updatedGalleryArray = realTimeGallery.filter(item =>
          gallery.some(newItem => newItem.image === item.image)
        );
        // Add new images
        gallery.forEach(item => {
          if (!updatedGalleryArray.some(existing => existing.image === item.image)) {
            updatedGalleryArray.push(item);
          }
        });

        sdk.ownListings.update({
          id: listingId,
          publicData: {
            gallery: updatedGalleryArray
          }
        }).then(r => {
          setInProgress(false);
          setManageGalleryModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
      });
    } else {
      post('/api/add-gallery',
        {
          listingId: listingId,
          currentGallery: gallery
        })
        .then(r => {
          setInProgress(false);
          setManageGalleryModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
    }
  }

  const fetchTransactions = () => {
    return sdk.transactions.query({
      listingId: listingId,
    }).then(r => {
      // console.log(r.data.data);
      setTransactions(r.data.data);
      setInProgress(false);
      // setCancelListingModalOpen(false);
      // onLoadData({ id: listingId }, null, config)
    }).catch(e => {
      setHasError(true);
    })
  }

  return (
    <section className={css.sectionAdministation}>
      <div className='col'>
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.administration" />
        </Heading>

        <div className='rowResponsive between fullWidth cenV'>
          {isOwnListing &&
            <>
              <PrimaryButton className={css.button} onClick={() => setCancelListingModalOpen(true)}>
                <FormattedMessage id="ListingPage.cancel" />
              </PrimaryButton>

              <PrimaryButton className={css.button} onClick={() => setAddCoHostModalOpen(true)}>
                <FormattedMessage id="ListingPage.addCoHost" />
              </PrimaryButton>
            </>
          }

          {!isOwnListing && isCoHost &&
            <PrimaryButton className={css.button} onClick={() => setEditListingModalOpen(true)}>
              <FormattedMessage id="ListingPage.editEvent" />
            </PrimaryButton>
          }

          {isOwnListing &&
            <PrimaryButton className={css.button} onClick={() => setManageGalleryModalOpen(true)}>
              <FormattedMessage id="ListingPage.manageGallery" />
            </PrimaryButton>
          }
        </div>
      </div>

      {isCancelListingModalOpen &&
        <CancelListingModal
          id="CancelListingModal"
          isOpen={isCancelListingModalOpen}
          onCloseModal={() => setCancelListingModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          currentUser={currentUser}
          inProgress={inProgress}
          hasError={hasError}
          transactions={transactions}
          fetchTransactions={fetchTransactions}
          onSubmitChanges={(values) => {
            onSubmitChanges();
          }}
        />
      }

      {isAddCoHostModalOpen &&
        <AddCoHostModal
          id="AddCoHostModal"
          isOpen={isAddCoHostModalOpen}
          onCloseModal={() => setAddCoHostModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          inProgress={inProgress}
          hasError={hasError}
          initialValues={{ coHostEmail: publicData.coHostEmail }}
          onSubmitChanges={(values) => {
            onAddCoHostChanges(values);
          }}
        />
      }

      {isEditListingModalOpen &&
        <EditListingDetailsModal
          id="EditListingDetailsModal"
          isOpen={isEditListingModalOpen}
          onCloseModal={() => setEditListingModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          inProgress={inProgress}
          hasError={hasError}
          listing={listing}
          config={config}
          onSubmitChanges={(values) => {
            onCoHostListingEdit(values)
          }}
        />
      }

      {isManageGalleryModalOpen &&
        <ManageGalleryModal
          id="ManageGalleryModal"
          isOpen={isManageGalleryModalOpen}
          onCloseModal={() => setManageGalleryModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          inProgress={inProgress}
          hasError={hasError}
          gallery={publicData.gallery}
          config={config}
          onSubmitChanges={(values) => {
            onUpdateGallery(values);
          }}
        />
      }
    </section>
  )
};

export default SectionAdministrationMaybe;
