import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton, Button } from '../../../components';
import css from './ManageGalleryModal.module.css';
import { required } from '../../../util/validators';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from './images/delete-icon.svg';
import FieldAzureImageUpload from '../../../components/FieldAzureImageUpload/FieldAzureImageUpload';

const ManageGalleryModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        gallery,
        currentUser,
        isCoHost,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'ManageGalleryModal.close' });

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="ManageGalleryModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id={isCoHost ? "ManageGalleryModal.messageCoHost" : "ManageGalleryModal.message"} />
            </p>

            <FinalForm
                {...props}
                onSubmit={(values) => {
                    onSubmitChanges(values)
                }}
                initialValues={{ gallery: gallery ? [...gallery, ''] : [''] }}
                mutators={{ ...arrayMutators }}
                render={fieldRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        intl,
                        formId,
                        invalid,
                        handleSubmit,
                        hasError,
                        inProgress,
                        form: {
                            mutators: { push, remove }
                        },
                        values,
                    } = fieldRenderProps;

                    const errorMessage =
                        <p className={css.error}>
                            <FormattedMessage id="ManageGalleryModal.cancellationSubmitFailed" />
                        </p>
                    const errorArea = hasError ? errorMessage : <p className={css.errorPlaceholder} />;

                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || disabled || submitInProgress;

                    // const handleOnChange = formValues => {
                    //     if (formValues.values && formValues.values.gallery) {
                    //         const index = formValues.values.gallery.findIndex(i =>
                    //             typeof i === 'object' && i !== '' && i.image === false);
                    //         if (index >= 0) {
                    //             remove('gallery', index);
                    //         }
                    //     }

                    //     if (formValues.values && formValues.values.gallery && formValues.values.gallery.filter(g => g === '').length === 0) {
                    //         push('gallery', '');
                    //     }
                    // };

                    return (
                        <Form onSubmit={handleSubmit}>
                            {/* <FormSpy subscription={{ values: true }} onChange={handleOnChange} /> */}
                            <div className={css.galleryContainer}>
                                <FieldArray name="gallery">
                                    {({ fields }) => fields.map((name, index) => {

                                        return (
                                            <div className={css.image} key={index}>
                                                <FieldAzureImageUpload
                                                    id={`${name}image`}
                                                    name={`${name}image`}
                                                    label={intl.formatMessage({ id: 'ManageGalleryModal.addPhoto' })}
                                                    onUploadComplete={() => {
                                                        push('gallery', '');
                                                    }}
                                                    onImageRemove={() => {
                                                        fields.remove(index)
                                                    }}
                                                    isRounded={false}
                                                />
                                            </div>
                                        )
                                    })}
                                </FieldArray>

                            </div>

                            {errorArea}
                            <div className={css.modalButtons}>
                                <SecondaryButton
                                    className={css.modalButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCloseModal();
                                    }}
                                >
                                    <FormattedMessage id="ManageGalleryModal.back" />
                                </SecondaryButton>

                                <PrimaryButton
                                    className={css.submitButton}
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                >
                                    <FormattedMessage id="ManageGalleryModal.confirm" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />


        </Modal>
    );
};

const { bool, string } = PropTypes;

ManageGalleryModal.defaultProps = {
    className: null,
    rootClassName: null,
};

ManageGalleryModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(ManageGalleryModal);
