import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton } from '../../../components';
import css from './ManageParticipantsModal.module.css';
import { required } from '../../../util/validators';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from './images/delete-icon.svg';

const ManageParticipantsModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        participants,
        currentUser,
        isCoHost,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'ManageParticipantsModal.close' });

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="ManageParticipantsModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id={isCoHost ? "ManageParticipantsModal.messageCoHost" : "ManageParticipantsModal.message"} />
            </p>

            <FinalForm
                {...props}
                onSubmit={(values) => {
                    onSubmitChanges(values)
                }}
                initialValues={{ participants: participants }}
                mutators={{ ...arrayMutators }}
                render={fieldRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        intl,
                        formId,
                        invalid,
                        handleSubmit,
                        hasError,
                        inProgress,
                        form: {
                            mutators: { push }
                        },
                        values,
                    } = fieldRenderProps;

                    const errorMessage =
                        <p className={css.error}>
                            <FormattedMessage id="ManageParticipantsModal.cancellationSubmitFailed" />
                        </p>
                    const errorArea = hasError ? errorMessage : <p className={css.errorPlaceholder} />;

                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || disabled || submitInProgress;

                    return (
                        <Form onSubmit={handleSubmit}>

                            <FieldArray name="participants">
                                {({ fields }) => fields.map((name, index) => {
                                    const value = values.participants[index]
                                    const handleDelete = () => {
                                        fields.remove(index);
                                    }

                                    return (

                                        <div className='col' key={index}>

                                            <div className='row gap20 center width-full'>
                                                <FieldTextInput
                                                    id={`${name}displayName`}
                                                    name={`${name}displayName`}
                                                    type="text"
                                                    className={css.input}
                                                    inputRootClass={css.input}
                                                    label={intl.formatMessage({ id: 'ManageParticipantsModal.displayName' })}
                                                    placeholder={intl.formatMessage({ id: 'ManageParticipantsModal.placeholder' })}
                                                />

                                                <FieldTextInput
                                                    id={`${name}profilePic`}
                                                    name={`${name}profilePic`}
                                                    type="text"
                                                    className={css.input}
                                                    inputRootClass={css.input}
                                                    label={intl.formatMessage({ id: 'ManageParticipantsModal.profilePictureUrl' })}
                                                    placeholder={intl.formatMessage({ id: 'ManageParticipantsModal.placeholder' })}
                                                />

                                                {values.participants[0] != '' &&
                                                    <div className='row' onClick={handleDelete}>
                                                        <img src={DeleteIcon} className={css.delete} />
                                                    </div>
                                                }
                                            </div>
                                            <div className='line mt20 mb20' />

                                        </div>
                                    )
                                })}
                            </FieldArray>

                            <div className='row' onClick={(e) => {
                                e.preventDefault();
                                push('participants', { displayName: undefined, addedByUserId: currentUser?.id.uuid });
                            }}>
                                <p className={css.addButton} >
                                    <FormattedMessage id="ManageParticipantsModal.addParticipant" />
                                </p>
                            </div>

                            {errorArea}
                            <div className={css.modalButtons}>
                                <SecondaryButton
                                    className={css.modalButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCloseModal();
                                    }}
                                >
                                    <FormattedMessage id="ManageParticipantsModal.back" />
                                </SecondaryButton>

                                <PrimaryButton
                                    className={css.submitButton}
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                >
                                    <FormattedMessage id="ManageParticipantsModal.confirm" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />


        </Modal>
    );
};

const { bool, string } = PropTypes;

ManageParticipantsModal.defaultProps = {
    className: null,
    rootClassName: null,
};

ManageParticipantsModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(ManageParticipantsModal);
