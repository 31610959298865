import React, { useRef, useState } from 'react';

import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton, Heading } from '../../components';

import css from './ListingPage.module.css';
import { post } from '../../util/api';
import moment from 'moment';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});


const SectionCommentsMaybe = props => {
  const { publicData, intl, isOwnListing, currentUser, listingId, onLoadData, config } = props;
  if (!publicData) {
    return null;
  }
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState();

  const comments = publicData.comments || [];
  const hasComments = comments && comments.length > 0;

  const onSubmitChanges = (values) => {

    const comment = {
      name: currentUser.attributes.profile.displayName,
      userId: currentUser.id.uuid,
      profilePic: currentUser?.profileImage?.attributes?.variants["square-small"].url,
      message: values.comment,
      createdAt: moment().toISOString()
    }

    setInProgress(true);
    if (isOwnListing) {
      sdk.ownListings.show({ id: listingId }).then(res => {
        const realTimeComments = Array.isArray(res.data.data.attributes.publicData.comments)
          ? res.data.data.attributes.publicData.comments
          : [];
        const allComments = [...realTimeComments, comment];

        sdk.ownListings.update({
          id: listingId,
          publicData: {
            comments: allComments
          }
        }).then(r => {
          setInProgress(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setError(true);
        })
      });
    } else {
      post('/api/add-comments',
        {
          listingId: listingId,
          currentComment: comment
        })
        .then(r => {
          setInProgress(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setError(true);
        })
    }

  }

  return (
    <section className={css.sectionComments}>


      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.commentsTitle" values={{ value: comments ? comments.length : 0 }} />
      </Heading>

      {hasComments ?
        <div className='col gap10 mb20'>
          {comments.map((comment, index) => (
            <div key={comment.createdAt} className={css.commentContainer}>

              {comment.profilePic ?
                <img src={comment.profilePic} className={css.commentPicture} alt="profile pic" />
                : <div className={css.commentNoPicture}>
                </div>
              }
              <div className='col'>
                <div className='row gap10'>
                  <p className={css.commentName}>{comment.name}</p>
                  <p className={css.commentName}>{moment(comment.createdAt).format('L LT')}</p>
                </div>

                <span>
                  {comment.message}
                </span>
              </div>
            </div>
          ))}
        </div>
        :
        <Heading as="h2" rootClassName={css.noComments}>
          <FormattedMessage id="ListingPage.noComments" />
        </Heading>
      }


      <FinalForm
        {...props}
        onSubmit={(values) => {
          onSubmitChanges(values)
        }}
        // initialValues={ }
        render={fieldRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            intl,
            formId,
            invalid,
            handleSubmit,
            reviewSent,
            form,
            values,
          } = fieldRenderProps;

          const submitInProgress = inProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const errorMessage =
            <p className={css.error}>
              <FormattedMessage id="ListingPage.commentSubmitFailed" />
            </p>
          const errorArea = error ? errorMessage : null;

          return (
            <Form onSubmit={handleSubmit}>
              <div className='col fullWidth gap10'>

                <FieldTextInput
                  id={`comment`}
                  name={`comment`}
                  type="textarea"
                  label={intl.formatMessage({ id: 'ListingPage.comment' })}
                  placeholder={intl.formatMessage({ id: 'ListingPage.commentPlaceholder' })}
                />

                {errorArea}

                <PrimaryButton
                  className={css.button}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={reviewSent}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                    form.change('comment', '');
                  }}
                >
                  <FormattedMessage id="ListingPage.sendComment" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />


    </section>
  )
};

export default SectionCommentsMaybe;
