import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, Modal, SecondaryButton } from '../../../components';
import css from './JoinWaitingListModal.module.css';
import { required } from '../../../util/validators';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from './images/delete-icon.svg';

const JoinWaitingListModal = props => {
    const {
        className,
        rootClassName,
        id,
        intl,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        onSubmitChanges,
        currentUser,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'JoinWaitingListModal.close' });

    return (
        <Modal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
            closeButtonMessage={closeButtonMessage}
        >
            <p className={css.modalTitle}>
                <FormattedMessage id="JoinWaitingListModal.title" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id="JoinWaitingListModal.message" />
            </p>

            <FinalForm
                {...props}
                onSubmit={(values) => {
                    const submittedValues = [
                        {
                            id: currentUser.id.uuid,
                            addedByUserId: currentUser.id.uuid,
                            email: currentUser.attributes.email,
                            displayName: currentUser.attributes.profile.displayName,
                            profilePic: currentUser.profileImage?.attributes.variants['square-small'].url,
                        },
                        ...values.waitingList
                    ];

                    onSubmitChanges(submittedValues);
                }}
                initialValues={{ waitingList: [] }}
                mutators={{ ...arrayMutators }}
                render={fieldRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        intl,
                        formId,
                        invalid,
                        handleSubmit,
                        hasError,
                        inProgress,
                        form: {
                            mutators: { push }
                        },
                        values,
                    } = fieldRenderProps;

                    const errorMessage =
                        <p className={css.error}>
                            <FormattedMessage id="JoinWaitingListModal.cancellationSubmitFailed" />
                        </p>
                    const errorArea = hasError ? errorMessage : <p className={css.errorPlaceholder} />;

                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || disabled || submitInProgress;

                    return (
                        <Form onSubmit={handleSubmit}>

                            <FieldArray name="waitingList">
                                {({ fields }) => fields.map((name, index) => {
                                    const value = values.waitingList[index]
                                    const handleDelete = () => {
                                        fields.remove(index);
                                    }

                                    return (

                                        <div className='col'>

                                            <div key={index} className='col gap20 center width-full'>
                                                <FieldTextInput
                                                    id={`${name}displayName`}
                                                    name={`${name}displayName`}
                                                    type="text"
                                                    className={css.input}
                                                    inputRootClass={css.input}
                                                    label={intl.formatMessage({ id: 'JoinWaitingListModal.displayName' })}
                                                    placeholder={intl.formatMessage({ id: 'JoinWaitingListModal.placeholder' })}
                                                />

                                                <FieldTextInput
                                                    id={`${name}email`}
                                                    name={`${name}email`}
                                                    type="text"
                                                    className={css.input}
                                                    inputRootClass={css.input}
                                                    label={intl.formatMessage({ id: 'JoinWaitingListModal.email' })}
                                                    placeholder={intl.formatMessage({ id: 'JoinWaitingListModal.placeholder' })}
                                                />

                                                <FieldTextInput
                                                    id={`${name}profilePic`}
                                                    name={`${name}profilePic`}
                                                    type="text"
                                                    className={css.input}
                                                    inputRootClass={css.input}
                                                    label={intl.formatMessage({ id: 'JoinWaitingListModal.profilePictureUrl' })}
                                                    placeholder={intl.formatMessage({ id: 'JoinWaitingListModal.placeholder' })}
                                                />

                                                {/* {values.waitingList[0] != '' &&
                                                    <div className='row' onClick={handleDelete}>
                                                        <img src={DeleteIcon} className={css.delete} />
                                                    </div>
                                                } */}
                                            </div>
                                            <div className='line mt20 mb20' />

                                        </div>
                                    )
                                })}
                            </FieldArray>

                            <div className='row' onClick={(e) => {
                                e.preventDefault();
                                push('waitingList', {
                                    displayName: undefined,
                                    addedByUserId: currentUser?.id.uuid
                                });
                            }}>
                                <p className={css.addButton} >
                                    <FormattedMessage id="JoinWaitingListModal.addPlayer" />
                                </p>
                            </div>

                            {errorArea}
                            <div className={css.modalButtons}>
                                <SecondaryButton
                                    className={css.modalButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCloseModal();
                                    }}
                                >
                                    <FormattedMessage id="JoinWaitingListModal.back" />
                                </SecondaryButton>

                                <PrimaryButton
                                    className={css.submitButton}
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                >
                                    <FormattedMessage id="JoinWaitingListModal.confirm" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />


        </Modal>
    );
};

const { bool, string } = PropTypes;

JoinWaitingListModal.defaultProps = {
    className: null,
    rootClassName: null,
};

JoinWaitingListModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
};

export default injectIntl(JoinWaitingListModal);
