import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, PrimaryButton } from '../../components';

import css from './ListingPage.module.css';
import SoccerIcon from './images/soccer_icon.svg';
import NoImageIcon from '../../components/ResponsiveImage/NoImageIcon';
import ManageParticipantsModal from './ManageParticipantsModal/ManageParticipantsModal';
import { post } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionParticipantsMaybe = props => {
  const { publicData, isOwnListing, intl, onManageDisableScrolling, currentUser, listingId, onLoadData, config } = props;
  if (!publicData) {
    return null;
  }


  const [isManageParticipantsModalOpen, setManageParticipantsModalOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);

  const isCoHost = currentUser ? currentUser?.attributes?.email === publicData.coHostEmail : false;

  const participants = publicData.participants;
  const hasParticipants = participants && participants.length > 0;

  const onSubmitChanges = (participants) => {
    const validParticipants = participants.filter(
      (p) => p.displayName && p.displayName.trim() !== ""
    );

    setInProgress(true);
    if (isOwnListing) {
      sdk.ownListings.show({ id: listingId }).then(res => {
        const realTimeParticipants = Array.isArray(res.data.data.attributes.publicData.participants)
          ? res.data.data.attributes.publicData.participants : [];

        // Keep only participants that still exist in the updated list
        const updatedParticipants = realTimeParticipants.filter(existing =>
          validParticipants.some(newItem =>
            newItem.addedByUserId === existing.addedByUserId &&
            newItem.transactionId === existing.transactionId &&
            newItem.displayName === existing.displayName
          )
        );

        // Function to check if a participant exists in realTimeParticipants
        const isExistingParticipant = (newItem) => {
          return realTimeParticipants.some(existing =>
            existing.addedByUserId === newItem.addedByUserId &&
            existing.transactionId === newItem.transactionId &&
            existing.displayName === newItem.displayName // Ensure correct match for cases with the same addedByUserId
          );
        };

        // Add new participants if they don’t already exist
        validParticipants.forEach(item => {
          if (!isExistingParticipant(item)) {
            updatedParticipants.push(item);
          }
        });

        sdk.ownListings.update({
          id: listingId,
          publicData: {
            participants: updatedParticipants
          }
        }).then(r => {
          setInProgress(false);
          setManageParticipantsModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
      })
    } else {
      post('/api/add-participants',
        {
          listingId: listingId,
          currentParticipantsArray: validParticipants
        })
        .then(r => {
          setInProgress(false);
          setManageParticipantsModalOpen(false);
          onLoadData({ id: listingId }, null, config)
        }).catch(e => {
          setHasError(true);
        })
    }
  }

  return (
    <section className={css.sectionParticipants}>
      <div className='rowResponsive between fullWidth cenV'>
        <Heading as="h2" rootClassName={css.participantsTitle}>
          <FormattedMessage id="ListingPage.participantsTitle" />
        </Heading>

        {(isOwnListing || isCoHost) &&
          <PrimaryButton className={css.button} onClick={() => setManageParticipantsModalOpen(true)}>
            <FormattedMessage id="ListingPage.manageParticipants" />
          </PrimaryButton>
        }
      </div>

      {hasParticipants ?
        <div className={css.participantsContainer}>
          {participants.map((user, index) => (
            <div key={`${index}-${user.displayName}`} className={css.participant}>

              {user.profilePic ?
                <img src={user.profilePic} className={css.participantImage} alt="profile pic" />
                : <div className={css.participantNoImageContainer}>
                  <img src={SoccerIcon} className={css.participantNoImage} />
                </div>
              }
              <div className='row cenV'>
                <img src={SoccerIcon} className={css.participantIcon} />

                <div className='col'>
                  <span className={css.participantName}>{" " + (index + 1) + ".  " + user.displayName}</span>
                  {/* <span className={css.participantLevel}>{user.playLevel}</span> */}
                </div>

              </div>
            </div>
          ))}
        </div>
        :
        <Heading as="h2" rootClassName={css.noParticipants}>
          <FormattedMessage id="ListingPage.noParticipants" />
        </Heading>
      }

      {isManageParticipantsModalOpen &&
        <ManageParticipantsModal
          id="ManageParticipantsModal"
          isOpen={isManageParticipantsModalOpen}
          onCloseModal={() => setManageParticipantsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          participants={participants}
          currentUser={currentUser}
          inProgress={inProgress}
          hasError={hasError}
          isCoHost={isCoHost}
          onSubmitChanges={(values) => {
            onSubmitChanges(values.participants);
          }}
        />
      }
    </section>
  )
};

export default SectionParticipantsMaybe;
